import { makeAutoObservable, runInAction, autorun, toJS } from "mobx";

import dayjs from "dayjs";
import pick from "lodash/pick";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";
import last from "lodash/last";
import remove from "lodash/remove";
import find from "lodash/find";
import { v4 as uuid } from "uuid";
import store from "store";
import { Howl } from "howler";

const SEND_RETRIES = 2;

const PERSIST_STORE_KEY_PREFIX = "chatState";

export default class ChatStore {
    chats = null;
    activeChatId = null;
    pendingMessages = {};
    chatStates = {};
    activeMedia = null;
    _pendingActions = [];
    _outgoingMessageQueue = [];

    constructor(config, state) {
        Object.assign(this, state);

        const persistKeys = getPersistKeys(config);
        const persistStorekey = `${PERSIST_STORE_KEY_PREFIX}${
            config.persistenceKey || ""
        }`;

        if (persistKeys.length > 0) {
            const existingState = store.get(persistStorekey);
            if (existingState) {
                persistKeys.forEach((key) => {
                    if (existingState[key]) {
                        this[key] = existingState[key];
                    }
                });
            }
        }

        makeAutoObservable(this);

        this._config = config;
        this._ticker = setInterval(() => this._tick(), 100);
        this._messagesInTransit = {};

        this._init(config, this.chats);

        if (persistKeys.length > 0) {
            autorun(() => {
                const persistedState = {};
                persistKeys.forEach((key) => {
                    persistedState[key] = toJS(this[key]);
                });
                store.set(persistStorekey, persistedState);
            });
        }

        if (config.onStateChange) {
            autorun(() => {
                config.onStateChange({
                    chats: toJS(this.chats),
                    chatStates: toJS(this.chatStates),
                    _pendingActions: toJS(this._pendingActions),
                    _outgoingMessageQueue: toJS(this._outgoingMessageQueue),
                });
            });
        }

        this._soundMessageIn = config.notificationSoundUrl
            ? new Howl({
                  src: [config.notificationSoundUrl],
              })
            : null;
    }

    get nonEmptySortedChatList() {
        const chats = (this.chats || []).filter((x) => x.messages.length > 0);
        return reverse(sortBy(chats, (x) => last(x.messages).time));
    }

    get sortedChatList() {
        return reverse(
            sortBy(
                this.chats || [],
                (x) => last(x.messages)?.time?.toString() || "0000"
            )
        );
    }

    get hasActiveChat() {
        return this.activeChatId !== null;
    }

    get activeChat() {
        if (this.activeChatId === null) {
            return null;
        }
        return this.getChatById(this.activeChatId);
    }

    get activePendingMessage() {
        if (this.activeChatId === null) {
            return "";
        }
        return this.pendingMessages[this.activeChatId] || "";
    }

    get canSendMessage() {
        return (
            !!this.activePendingMessage &&
            this.activePendingMessage.trim() !== ""
        );
    }

    get notificationCounter() {
        return (this.chats || []).reduce(
            (sum, chat) => sum + chat.unreadCount,
            0
        );
    }

    dispose() {
        if (this._ticker) {
            clearInterval(this._ticker);
        }
    }

    _init({ chats, onChatStart }, existingChats) {
        this.chats = chats.map((chat) => {
            const existing =
                find(existingChats || [], (x) => x.id === chat.id) || {};
            return {
                messages: chat.initialMessages || [],
                typing: false,
                connected: false,
                unreadCount: 0,
                ...pick(chat, [
                    "id",
                    "title",
                    "picture",
                    "group",
                    "members",
                    "hideEmpty",
                ]),
                ...pick(existing, ["messages", "inactive"]),
            };
        });

        chats.forEach((chat) => {
            if (chat.actions) {
                this._processActions({
                    chatId: chat.id,
                    nextStateId: "start",
                    actions: chat.actions,
                });
            }
        });

        if (onChatStart) {
            this.chats.forEach((chat) => {
                if (!chat.startEventDispatched) {
                    onChatStart({ chatId: chat.id }).then(({ data }) => {
                        chat.startEventDispatched = true;
                        if (data) {
                            this._processActions(data);
                        }
                    });
                }
            });
        }
    }

    setActiveChat(chatId) {
        this.activeChatId = chatId;
        if (chatId) {
            this.getChatById(chatId).unreadCount = 0;
        }
    }

    unsetActiveChat() {
        this.activeChatId = null;
    }

    setActivePendingMessage(message) {
        this.pendingMessages[this.activeChatId] = message;
    }

    sendMessage() {
        if (!this.canSendMessage) {
            return;
        }

        const { activeChat } = this;
        const messageText = this.activePendingMessage.trim();

        const message = {
            id: uuid(),
            chatId: activeChat.id,
            type: "text",
            direction: "out",
            time: dayjs(),
            text: messageText,
            status: "pending",
        };
        activeChat.messages.push(message);

        this._enqueueOutgoingMessage({
            chatId: activeChat.id,
            id: message.id,
            messageText,
        });
        this.setActivePendingMessage("");
        this._expireActionsByType(activeChat.id, "message");
    }

    sendHiddenMessage({ chatId, messageText }) {
        this._enqueueOutgoingMessage({
            chatId,
            stateId: this.chatStates[chatId],
            messageText,
        });
    }

    showMedia(media) {
        this.activeMedia = media;
    }

    hideActiveMedia() {
        this.activeMedia = null;
    }

    _enqueueOutgoingMessage({ chatId, id, messageText }) {
        this._outgoingMessageQueue.push({
            id: id || uuid(),
            chatId,
            stateId: this.chatStates[chatId],
            messageText,
            retriesLeft: SEND_RETRIES,
        });
        this._purgeOutgoingMessagesQueue();
    }

    _processActions({ chatId, nextStateId, actions }) {
        if (nextStateId) {
            this._setState(chatId, nextStateId);
        }
        if (actions) {
            this._enqueueActions(chatId, actions);
        }
    }

    _enqueueActions(chatId, actions) {
        actions.forEach((action) => {
            this._pendingActions.push({
                time: new Date().getTime() + action.delay * 1000,
                chatId: action.chatId || chatId,
                action,
            });
        });
    }

    _executeReadyActions() {
        if (this._pendingActions.length === 0) {
            return;
        }

        const now = new Date().getTime();
        const remainingActions = [];
        let executedAny = false;

        this._pendingActions.forEach((action) => {
            if (action.time > now) {
                remainingActions.push(action);
                return;
            }

            this._executeAction(action.chatId, action.action);
            executedAny = true;
        });

        if (executedAny) {
            this._pendingActions = remainingActions;
        }
    }

    _executeAction(chatId, action) {
        switch (action.type) {
            case "setState":
                this._setState(chatId, action.state);
                break;
            case "toggleConnected":
                this.getChatById(chatId).connected = action.isConnected;
                break;
            case "toggleTyping":
                this.getChatById(chatId).typing = action.isTyping
                    ? action.from || true
                    : false;
                break;
            case "message":
                this._pushMessage(chatId, {
                    chatId,
                    type: action.messageType,
                    direction: "in",
                    from: action.from,
                    time: dayjs(),
                    text: action.messageText,
                    image: action.messageImgUrl,
                    video: action.messageVideoUrl,
                    videoThumbnail: action.messageVideoThumbnail,
                    audio: action.messageAudioUrl,
                });
                break;
            case "deactivate":
                this._toggleChatActive(chatId, false);
                break;
            case "event":
                if (this._config.onEvent) {
                    this._config.onEvent({
                        name: action.eventName,
                        data: action.eventData || {},
                        chatId,
                    });
                }
                break;
            case "clearall":
                this.clearAllChats();
                break;
            default:
                // console.error("unknown action type", action.type);
                break;
        }
    }

    _setState(chatId, nextStateId) {
        this.chatStates[chatId] = nextStateId;
    }

    _toggleChatActive(chatId, isActive) {
        const chat = this.getChatById(chatId);
        if (chat) {
            chat.inactive = !isActive;
        }
    }

    _pushMessage(chatId, message) {
        const chat = this.getChatById(chatId);
        chat.messages.push(message);

        if (message.direction === "in") {
            if (this._soundMessageIn) {
                this._soundMessageIn.play();
            }

            if (chatId !== this.activeChatId) {
                chat.unreadCount += 1;
            }
        }
    }

    _expireActionsByType(chatId, actionType) {
        remove(
            this._pendingActions,
            (item) =>
                item.chatId === chatId && item.action.expireOn === actionType
        );
    }

    _purgeOutgoingMessagesQueue() {
        this._outgoingMessageQueue.forEach((message) => {
            if (this._messagesInTransit[message.id]) {
                return;
            }
            this._messagesInTransit[message.id] = true;

            const chat = this.getChatById(message.chatId);

            this._config
                .onMessage({
                    chatId: message.chatId,
                    stateId: message.stateId,
                    messageText: message.messageText,
                })
                .then(({ data }) => {
                    this._dequeueOutgoingMessage(chat, message.id);
                    this._processActions(data);
                    if (data.sessionId && this._config.onReceivedSessionId) {
                        this._config.onReceivedSessionId(data.sessionId);
                    }
                })
                .catch(() => {
                    runInAction(() => {
                        message.retriesLeft -= 1;
                        if (message.retriesLeft <= 0) {
                            this._dequeueOutgoingMessage(
                                chat,
                                message.id,
                                "error"
                            );
                        }
                    });
                })
                .finally(() => {
                    delete this._messagesInTransit[message.id];
                });
        });
    }

    _dequeueOutgoingMessage(chat, messageId, status) {
        updateMessageStatus(chat, messageId, status);

        this._outgoingMessageQueue = this._outgoingMessageQueue.filter(
            (x) => x.id !== messageId
        );
    }

    _tick = () => {
        this._executeReadyActions();
        this._purgeOutgoingMessagesQueue();
    };

    getChatById(chatId) {
        return find(this.chats, (x) => x.id === chatId);
    }

    clearAllChats() {
        this.chatStates = {};
        this.activeMedia = null;
    }
}

function updateMessageStatus(chat, messageId, newStatus) {
    if (!messageId) {
        return;
    }

    const message = find(chat.messages, (x) => x.id === messageId);
    if (!message) {
        return;
    }

    if (!newStatus) {
        delete message.status;
    } else {
        message.status = newStatus;
    }
}

function getPersistKeys(config) {
    const persistKeys = [];
    if (config.presistActiveChat) {
        persistKeys.push("activeChatId");
    }
    if (config.presistChats) {
        persistKeys.push(
            "chats",
            "chatStates",
            "_pendingActions",
            "_outgoingMessageQueue"
        );
    }
    return persistKeys;
}
